<template>
    <el-dialog :title="!dataForm.id ? '指派投诉' : '指派投诉'" :close-on-click-modal="false" :modal-append-to-body="false"
        :lock-scroll="false" :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
          <el-form-item prop="cUserId" label="网格经理">
            <el-select v-model="dataForm.cUserId" placeholder="请选择要指派的网格经理">
                <el-option v-for="item in dataList" :key="item.userId" :label="item.username" :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false" type="primary">取消</el-button>
            <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                cUserId: '', //要指派的网格经理id
                ids: '' //投诉id列表
            },
            dataList: [],
            dataRule: {
                areaCode: [
                    { areaCode: true, message: '行政区域不能为空', trigger: 'blur' }
                ],
                cUserId: [
                    { required:true, message:'指派的网格经理不能为空',trigger:'change'}
              ],
            }
        }
    },
    methods: {
        init(ids) {
            this.dataForm.ids = ids
            console.log(ids)
            const params = {
                'ids': this.dataForm.ids,
            }
            this.visible = true
            this.getUserList();
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$http({
                        url: this.$http.adornUrl(`/dataflow/olitusercomplaint/updateGradeByids`),
                        method: 'post',
                        data: this.$http.adornData({
                            'ids': this.dataForm.ids,
                            'sysUserId': this.dataForm.cUserId,
                            'grade': "2",
                            'state':'2'
                        })
                    }).then(({ data }) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                            })
                            this.visible = false
                            this.$emit('refreshDataList')
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        getUserList() {
            // console.log("getUserList==>"+this.$store.state.user.id)
            this.$http({
                url: this.$http.adornUrl("/dataflow/olfgridmanage/griddingManager"),
                method: 'get',
                params: {userId: this.$store.state.user.id}
            }).then(({ data }) => {
                console.log("data=>" + JSON.stringify(data))
                if (data && data.code === 0) {
                    this.dataList = data.data
               
                } else {
                    this.dataList = []
                    this.$message.error(data.msg)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
            })
        }   
    },
    computed: {
        regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                console.log("regionId=====>" + newregionId)
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                console.log("gridId=====>" + newgridId)
                if (newgridId !== undefined) {
                    return Number(newgridId);
                } else {
                    return ''
                }
            }
        },
        regionCode: {
            get() {
                console.log("regionCode=====>" + this.$store.state.user.regionCode)
                return this.$store.state.user.regionCode;
            }
        },
        gridCode: {
            get() {
                console.log("gridCode=====>" + this.$store.state.user.gridCode)
                return this.$store.state.user.gridCode;
            }
        },
    }
}
</script>
  